<template>
  <div class="c_course_notice">
    <div class="stu-module-header">
      <div class="stu-module-title">课程公告</div>
    </div>
    <el-form :model="form" inline ref="c_course_notice_ref" size="medium">
      <el-form-item prop="title" label="标题">
        <el-input v-model="form.title" clearable></el-input>
      </el-form-item>
      <el-form-item prop="noticeStatus" label="状态">
        <el-select v-model="form.noticeStatus" clearable>
          <el-option
            v-for="(item, index) in stateOption"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="publish" label="发布人">
        <el-input v-model="form.publish" clearable></el-input>
      </el-form-item>
      <el-form-item prop="publishTime" label="发布时间">
        <el-date-picker
          v-model="publishTime"
          type="daterange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="query" type="primary" :loading="loading"
          >查询</el-button
        >
        <el-button @click="reset('c_course_notice_ref')">重置</el-button>
      </el-form-item>
    </el-form>

    <el-table
      class="c_course_notice_table"
      :data="tableData"
      :height="height"
      ref="tableRef"
      highlight-current-row
      v-loading="loading"
      @row-click="rowClick"
      stripe
    >
      <el-table-column label="序号" :width="60" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in columns"
        :prop="item.prop"
        :key="index"
        :label="item.label"
        align="center"
        :width="item.width"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="noticeStatus"
        label="状态"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          <el-button type="text" class="state_btn">{{
            scope.row.noticeStatus
          }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <el-drawer title="" :visible.sync="selectRow.drawer" size="40%">
      <div class="drawer-title">{{ selectRow.title }}</div>
      <div class="drawer-time">{{ selectRow.createTime }}</div>
      <div class="drawer-content" v-html="selectRow.content"></div>
    </el-drawer>
  </div>
</template>

<script>
import { CourseNotice, QueryCourseNoticeById } from "@/libs/api/course";
export default {
  name: "c_course_notice",
  data() {
    return {
      loading: false,
      height: window.innerHeight - 430,
      form: {
        title: null,
        noticeStatus: null,
        publish: null,
      },
      publishTime: [],
      stateOption: [
        { label: "全部", value: "1" },
        { label: "已读", value: "2" },
        { label: "未读", value: "3" },
      ],

      tableData: [],
      columns: [
        { prop: "title", label: "标题", tooltip: true },
        { prop: "name", label: "发布人", width: 100 },
        { prop: "publishTime", label: "时间", tooltip: true },
      ],
      pageSize: 10,
      current: 1,
      total: 0,
      selectRow: {
        drawer: false,
        title: null,
        content: null,
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    course() {
      return this.$store.getters.getStuMyCourse;
    },
  },
  watch: {
    course(n) {
      n && this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.init();
      }
    },
    reset(refName) {
      this.$refs[refName].resetFields();
    },
    query() {
      this.init();
    },
    init() {
      this.loading = true;
      let p = {
        page: this.current,
        pageSize: this.pageSize,
      };
      let data = {
        page: this.current,
        pageSize: this.pageSize,
        courseId: this.course.id,
        studentId: this.user.id,
        title: this.form.title,
        noticeStatus: this.form.noticeStatus,
        publish: this.form.publish,
      };
      if (this.publishTime[0]) {
        data.startTime = `${this.publishTime[0]} 00:00:00`;
      }
      if (this.publishTime[1]) {
        data.endTime = `${this.publishTime[1]} 00:00:00`;
      }
      CourseNotice(p, data)
        .then((res) => {
          if (res.data) {
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
          this.stateCount();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    stateCount() {
      // 课程公告未读数量
      const len = this.tableData.filter(
        (f) => f.noticeStatus === "未读"
      ).length;
      this.$store.dispatch("set_stu_course_notice", len);
    },
    rowClick(row) {
      if (row) {
        this.selectRow.drawer = true;
        const { id, noticeId } = row;
        QueryCourseNoticeById(
          {
            id,
            noticeId,
          },
          {
            courseId: this.course.id,
          }
        ).then((res) => {
          this.selectRow = {
            drawer: true,
            ...res.data,
          };
          this.init();
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.c_course_notice {
  background: #fff;
  padding: 20px;
  box-shadow: @shadow;
  .stu-module-header {
    margin-bottom: 30px;
  }
  .c_course_notice_table {
    margin-top: 10px;
  }
}
</style>
<style lang="less">
.c_course_notice {
  .drawer-title {
    font-size: 22px;
    padding: 0 20px;
    margin-bottom: 20px;
    font-weight: 500;
  }
  .drawer-time {
    color: #aaa;
    padding: 0 20px;
    margin-bottom: 20px;
  }
  .el-table {
    .state_btn {
      span {
        color: #ff9b22;
      }
    }
  }

  .drawer-content {
    padding: 0 20px;
    img {
      width: 100%;
      max-width: 300px;
    }
  }
}
</style>
<style>
    .drawer-content p{
      width: 25%;
    }
</style>